import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { Component, ViewChild, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { BrailleService } from 'src/app/services/braille.service';
import { FormControl } from '@angular/forms';
import { trimEnd } from 'src/app/helpers/functions';
import { AngularFirestore } from '@angular/fire/firestore';
import {LocalStorageService} from 'src/app/services/localStorage.service';
import swal from 'sweetalert2';

export type Level = {
  data: any[];
  level: number;
  id: string;
  title: string;
}

@Component({
  selector: 'app-braille-writer',
  templateUrl: './braille-writer.component.html',
  styleUrls: ['./braille-writer.component.scss']
})
export class BrailleWriterComponent {
  activeKeys = {};

  textarea = new FormControl('');

  @ViewChild('_textarea', {static: false}) _textarea: ElementRef;
  textareaScrollWatching = false;
  textareaScrollTop = 0;

  currentWordId: number;

  answerResult: boolean;

  level: Level;

  constructor(
    private braille: BrailleService,
    private route: ActivatedRoute,
    private db: AngularFirestore,
    private ls: LocalStorageService,
  ) { 
    this.getData(this.route.snapshot.queryParamMap.get('level') || '1');
  }

  async getData(_level){
    const level = await this.db.collection('braille-writer', q => q.where('level', '==', parseInt(_level))).get().pipe(map(a => a.docs.map(b => ({...b.data(), id: b.ref.id})))).toPromise();

    if(level.length !== 1){
      throw Error('Level does not exist.');
    }

    this.level = _.first(level) as any;
    this.level.data = _.map(this.level.data, (v, i) => {
      if(/\r\n/.test(v.braille)){
        v.braille = _.replace(v.braille, /\r\n/g, '\n');
      }

      return v;
    });
    this.currentWordId = this.ls.findFirstUncompletedWord(this.level.level, this.level.data.length);
    if (this.ls.isCompleted(this.level.level, this.currentWordId)) {
      this.answerResult = true;
    }

    setTimeout(() => {
      this.watchScroll();
    }, 100);
  }

  watchScroll(){
    if(!this.textareaScrollWatching){
      this._textarea.nativeElement.addEventListener('scroll', (e) => {
        this.textareaScrollTop = -this._textarea.nativeElement.scrollTop;
      });

      this.textareaScrollWatching = true;
    }
  }

  getHighlighted(){
    let innerHTML = '';

    const content = trimEnd(this.textarea.value);

    for(let i = 0; i < content.length; i++){
      let letter = content[i];

      if(/(\r\n|\r|\n)/.test(letter)){
        innerHTML += '<br />';
        continue;
      }

      innerHTML += letter !== this.level.data[this.currentWordId].braille[i] ? `<mark>${letter === ' ' ? '_' : letter}</mark>` : `<span>${letter}</span>`;
    }

    return innerHTML;
  }

  async check(){
    const value = trimEnd(this.textarea.value);
    const expectedValue = this.level.data[this.currentWordId].braille;
    this.answerResult = value === expectedValue;

    if (this.answerResult === true) {
      const levelCompleted = this.ls.track(this.level, this.currentWordId);

      // last word and level is not completed
      if (this.currentWordId === this.level.data.length - 1 && !levelCompleted) {
        const uncompletedWords = this.ls.getUncompletedWords(this.level.level, this.level.data.length);

        if (uncompletedWords.length) {
          swal.fire({
            icon: 'warning',
            title: 'Oops!',
            text: `Looks like you need to complete and check items ${uncompletedWords.map(wordId => wordId + 1).join(', ')}.`,
          })
  
          this.answerResult = undefined;
          this.currentWordId = uncompletedWords[0] || 0;
          this.textarea.setValue('');
        }
      }
    }
  }

  keydown(e){
    if(typeof this.answerResult === 'boolean'){
      this.answerResult = null;
    }

    if( [8, 13, 37, 38, 39, 40, 32].includes(e.which) ){
      return;
    }

    if (this.braille.keys.includes(e.which)) {

      Object.defineProperty(this.activeKeys, e.which, {
        writable: true,
        enumerable: true,
        value: true
      });
    }

    e.preventDefault();
  }

  keyup(e){
    if( [8, 13, 37, 38, 39, 40, 32].includes(e.which) ){
      return;
    }

    if (this.braille.keys.includes(e.which)) {
      this.activeKeys[e.which] = false;
    }

    if (Array.prototype.every.call(_.values(this.activeKeys), v => v === false)) {
      const pressed = this.braille.getLetterByKeys(this.activeKeys);
      
      if(pressed){
        const start = this._textarea.nativeElement.selectionStart;
        const end = this._textarea.nativeElement.selectionEnd;

        const {value} = this.textarea;
        
        this.textarea.setValue(`${value.slice(0, start)}${pressed}${value.slice(end)}`);

        this._textarea.nativeElement.setSelectionRange(start + pressed.length, start + pressed.length);
      }

      this.activeKeys = {};
    }

    e.preventDefault();
  }

  prev(){
    if(this.currentWordId === 0){
      return;
    }

    this.answerResult = undefined;
    this.currentWordId -= 1;
    this.textarea.setValue('');

    if (this.ls.isCompleted(this.level.level, this.currentWordId)) {
      this.answerResult = true;
    }
  }

  next(){
    if(this.currentWordId === this.level.data.length){
      return;
    }

    this.answerResult = undefined;
    this.currentWordId += 1;
    this.textarea.setValue('');
    this._textarea.nativeElement.focus();

    if (this.ls.isCompleted(this.level.level, this.currentWordId)) {
      this.answerResult = true;
    }
  }

}
