import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class BrailleService {
  public keys = [83, 68, 70, 74, 75, 76, 69, 87, 73, 79];

  private ascii = {
    '=': [1,1,1,1,1,1],
    'y': [1,0,1,1,1,1],
    'q': [1,1,1,1,1,0],
    '&': [1,1,1,1,0,1],
    '(': [1,1,1,0,1,1],
    ')': [0,1,1,1,1,1],
    ']': [1,1,0,1,1,1],
    'g': [1,1,0,1,1,0],
    'n': [1,0,1,1,1,0],
    'p': [1,1,1,1,0,0],
    'r': [1,1,1,0,1,0],
    't': [0,1,1,1,1,0],
    'v': [1,1,1,0,0,1],
    'x': [1,0,1,1,0,1],
    'w': [0,1,0,1,1,1],
    'z': [1,0,1,0,1,1],
    '$': [1,1,0,1,0,1],
    '\\': [1,1,0,0,1,1],
    '?': [1,0,0,1,1,1],
    '!': [0,1,1,1,0,1],
    '7': [0,1,1,0,1,1],
    '#': [0,0,1,1,1,1],
    'd': [1,0,0,1,1,0],
    'f': [1,1,0,1,0,0],
    'h': [1,1,0,0,1,0],
    'j': [0,1,0,1,1,0],
    'l': [1,1,1,0,0,0],
    'm': [1,0,1,1,0,0],
    'o': [1,0,1,0,1,0],
    's': [0,1,1,1,0,0],
    'u': [1,0,1,0,0,1],
    '<': [1,1,0,0,0,1],
    '%': [1,0,0,1,0,1],
    '6': [0,1,1,0,1,0],
    '8': [0,1,1,0,0,1],
    '[': [0,1,0,1,0,1],
    '4': [0,1,0,0,1,1],
    '>': [0,0,1,1,1,0],
    '+': [0,0,1,1,0,1],
    '_': [0,0,0,1,1,1],
    '0': [0,0,1,0,1,1],
    ':': [1,0,0,0,1,1],
    'b': [1,1,0,0,0,0],
    'c': [1,0,0,1,0,0],
    'e': [1,0,0,0,1,0],
    'i': [0,1,0,1,0,0],
    'k': [1,0,1,0,0,0],
    '*': [1,0,0,0,0,1],
    '2': [0,1,1,0,0,0],
    '3': [0,1,0,0,1,0],
    '5': [0,1,0,0,0,1],
    '/': [0,0,1,1,0,0],
    '9': [0,0,1,0,1,0],
    '-': [0,0,1,0,0,1],
    '^': [0,0,0,1,1,0],
    '.': [0,0,0,1,0,1],
    ';': [0,0,0,0,1,1],
    'a': [1,0,0,0,0,0],
    '1': [0,1,0,0,0,0],
    '\'': [0,0,1,0,0,0],
    '@': [0,0,0,1,0,0],
    '"': [0,0,0,0,1,0],
    ',': [0,0,0,0,0,1]
  };

  constructor() { }

  /**
   * Function that gets letter by keys
   */
  // public getLetterByKeys(keys: any): string {
  //   if (!keys) {
  //     return null;
  //   }

  //   keys = _.keys(keys);

  //   if (!keys.length) {
  //     return null;
  //   }

  //   keys = keys.map(i => parseInt(String(i), 10));

  //   keys = keys.sort();

  //   return _.findKey(this.alphabet, (o) => _.isEqual(o.sort(), keys)) || null;
  // }

  getLetterByKeys(activeKeys = {}){
    let args: any = [
      _.has(activeKeys, ['70']), 
      _.has(activeKeys, ['68']) || _.has(activeKeys, ['69']),
      _.has(activeKeys, ['83']) || _.has(activeKeys, ['87']),
      _.has(activeKeys, ['74']),
      _.has(activeKeys, ['73']) || _.has(activeKeys, ['75']),
      _.has(activeKeys, ['76']) || _.has(activeKeys, ['79'])
    ]

    if(!args || args.length !== 6){
        throw Error(`Ascii args are missing.`);
    }

    // console.log(args);

    args = _.flatMap(args, (i) => i === true ? 1 : 0);

    return _.first(_.filter(Object.keys(this.ascii), key => {
        if(this.ascii[key] === args) return true;
        if (this.ascii[key] == null || args == null) return false;
        if(this.ascii[key].length != args.length) return false;

        for(let i=0; i<args.length; i++){
            if (this.ascii[key][i] !== args[i]) return false
        }

        return true;
    }));
  }
}
