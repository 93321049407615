import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import {Level} from '../components/braille-writer/braille-writer.component';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private tracker: Record<string, number[]> = {};

  constructor() {
    try {
      const raw = localStorage.getItem('tracking');

      if (raw) {
        this.tracker = JSON.parse(localStorage.getItem('tracking'));
      } else {
        this.tracker = {};
      }
    } catch (e) {
      localStorage.removeItem('tracking');
      this.tracker = {};
    }
  }

  public isCompleted(level: number, wordId: number): boolean {
    if (!this.tracker[level]) {
      return false;
    }

    return this.tracker[level].includes(wordId);
  }

  findFirstUncompletedWord(level: number, maxWords: number) {
    if (!this.tracker[level]) {
      return 0;
    }

    return this.getUncompletedWords(level, maxWords)[0] || 0;
  }

  public getUncompletedWords(level: number, maxWords: number): number[] {
    if (!this.tracker[level]) {
      return [];
    }

    return Array.from({length: maxWords}).map((_, index) => index).filter(wordId => !this.tracker[level].includes(wordId));
  }

  track({level, data}: Level, word: number): boolean {
    if (!this.tracker[level]) {
      this.tracker[level] = [];
    }

    if (!this.tracker[level].includes(word)) {
      this.tracker[level].push(word);

      // completed all levels
      if (this.tracker[level].length === data.length) {
        swal.fire({
          icon: 'success',
          title: 'Yay!',
          text: 'You have completed all the items!',
        })
      }
    }

    localStorage.setItem('tracking', JSON.stringify(this.tracker));
    return this.tracker[level].length === data.length;
  }
}
