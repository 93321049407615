import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrailleWriterComponent } from './components/braille-writer/braille-writer.component';
import { BrailleSimulatorComponent } from './components/braille-simulator/braille-simulator.component';
import { FileSaverModule } from 'ngx-filesaver';
import { InputsComponent } from './components/inputs/inputs.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

@NgModule({
  declarations: [
    AppComponent,
    BrailleWriterComponent,
    BrailleSimulatorComponent,
    InputsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FileSaverModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
