import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BrailleWriterComponent } from './components/braille-writer/braille-writer.component';
import { BrailleSimulatorComponent } from './components/braille-simulator/braille-simulator.component';
import { InputsComponent } from './components/inputs/inputs.component';


const routes: Routes = [
  {
    path: 'braille-simulator',
    component: BrailleSimulatorComponent
  },
  {
    path: 'braille-writer',
    component: BrailleWriterComponent
  },
  {
    path: 'inputs',
    component: InputsComponent
  },
  {
    path: '',
    redirectTo: 'braille-writer',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
