import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BrailleService } from 'src/app/services/braille.service';
import * as _ from 'lodash';
import * as decoder from 'base64-utf8';
import swal from 'sweetalert2';
import FileSaver from 'file-saver';
import * as moment from 'moment';
import { FileSaverService } from 'ngx-filesaver';

@Component({
  selector: 'app-braille-simulator',
  templateUrl: './braille-simulator.component.html',
  styleUrls: ['./braille-simulator.component.scss']
})
export class BrailleSimulatorComponent {
  activeKeys = {};

  textarea = new FormControl('');
  printable: string;

  @ViewChild('_textarea', {static: true}) _textarea: ElementRef;

  constructor(
    private braille: BrailleService,
    private fileSaver: FileSaverService
  ) {}

  keydown(e){
    if( [8, 13, 37, 38, 39, 40, 32].includes(e.which) ){
      return;
    }

    if (this.braille.keys.includes(e.which)) {

      Object.defineProperty(this.activeKeys, e.which, {
        writable: true,
        enumerable: true,
        value: true
      });
    }

    e.preventDefault();
  }

  keyup(e){
    if( [8, 13, 37, 38, 39, 40, 32].includes(e.which) ){
      return;
    }

    if (this.braille.keys.includes(e.which)) {
      this.activeKeys[e.which] = false;
    }

    if (Array.prototype.every.call(_.values(this.activeKeys), v => v === false)) {
      const pressed = this.braille.getLetterByKeys(this.activeKeys);
      
      if(pressed){
        const start = this._textarea.nativeElement.selectionStart;
        const end = this._textarea.nativeElement.selectionEnd;

        const {value} = this.textarea;
        
        this.textarea.setValue(`${value.slice(0, start)}${pressed}${value.slice(end)}`);

        this._textarea.nativeElement.setSelectionRange(start + pressed.length, start + pressed.length);
      }

      this.activeKeys = {};
    }

    e.preventDefault();
  }

  onSave(){
    try{
      let content = this.textarea.value;

      if(!window.Blob){
          throw Error('Your browser does not support saving Braille Ready Format files. Please update your browser.');
      }

      let blob = new Blob([content], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, `braille_${moment().format('YYYY_MM_DD')}.brf`);

    }catch({message}){
      swal.fire({
        icon: 'error',
        title: 'Oops.',
        text: message
      })
    }
  }

  onUpload(file){
    if(!file){
      return swal.fire({
        icon: 'error',
        title: 'Could not load this file.'
      })
    }

    const reader = new FileReader;
    reader.onload = (e: any) => {
        let res = e.target.result;

        res = String.prototype.split.call(res, 'base64,');
        res = decoder.decode(res[1]);

        if(res){
          this.textarea.setValue(res);
        }
    }
    reader.onerror = (e) => {
      swal.fire({
        icon: 'error',
        title: 'Something went wrong.'
      })
    }
    reader.readAsDataURL(file);
  }

  print(){
    this.printable = _.split(this.textarea.value, '\n').join('<br />');

    setTimeout(() => {
      window.print();
    }, 100);
  }

  async download(){
    try{
      const {value: filename} = await swal.fire({
        input: 'text',
        inputValue: 'Braille',
        title: 'Save file',
        text: 'Enter file name',
        confirmButtonText: 'Save',
        showCancelButton: true,
        inputValidator: v => !v ? 'Please enter file name' : ''
      })

      if(!filename){
        return;
      }

      if(!window.Blob){
        throw Error('Your browser does not support saving Braille Ready Format files. Please update your browser.');
      }

      let blob = new Blob([this.textarea.value], {type: "text/plain;charset=utf-8"});
      this.fileSaver.save(blob, `${filename}.brf`);
    }catch({message}){
      swal.fire('Oops', message, 'error');
    }
  }
}
