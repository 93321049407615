import { FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss']
})
export class InputsComponent {
  data: any[];
  current: number;

  input = new FormControl();

  showResults = false;

  mode = 1;

  constructor(
    private route: ActivatedRoute,
    private db: AngularFirestore
  ) { 
    this.getData(this.route.snapshot.queryParamMap.get('level') || '1');
  }

  async getData(_level){
    const level = await this.db.collection('braille-to-text', q => q.where('level', '==', parseInt(_level))).get().pipe(map(a => a.docs.map(b => ({...b.data(), id: b.ref.id})))).toPromise();

    if(!level.length){
      throw Error('Level does not exist.');
    }

    let data: any = _.flatten(_.map(_.concat(level), (q: any) => _.map(q.data, v => ({
      ...v,
      mode: q.mode,
      q_order: q.order,
      title: q.title
    }))));

    data = _.sortBy(data, ['q_order', 'order']);

    data = _.map(data, v => ({
      ...v,
      words: _.map(v.words, v => ({
        braille: _.split(v.braille, '\n'),
        print: _.trim(v.print),
        input: new FormControl()
      }))
    }))

    this.data = data;

    this.current = 0;
  }

  isValid(word, value){
    // replace special character
    value = _.replace(value, new RegExp('[‘’‛]', 'gi'), "'");
    value = _.replace(value, new RegExp('[“”„‟⹂]', 'gi'), '"');
    
    return word === value;
  }

  check(){
    this.showResults = true;
  }
}
